import React from 'react';
import LinkSummaryApp from './components/LinkSummaryApp';

function App() {
  return (
    <div className="App">
      <LinkSummaryApp />
    </div>
  );
}

export default App;
